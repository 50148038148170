import * as Sentry from "@sentry/nuxt";
import { useExtractQueryParams } from "~/composables";

export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const { userType } = storeToRefs(useUserStore($pinia));

  if (userType.value !== "guest") {
    return;
  }

  const { registrationParams } = storeToRefs(useOnboardingStore($pinia));
  const { getUndefinedMandatoryRegistrationParams } = useExtractQueryParams();

  const undefinedMandatoryParams = getUndefinedMandatoryRegistrationParams(registrationParams.value);

  if (undefinedMandatoryParams.length) {
    Sentry.captureMessage("Checkout attempt has missing mandatory registration params.", {
      extra: {
        undefinedMandatoryParams: undefinedMandatoryParams.toString(),
        requestURL: to.fullPath,
      },
    });
  }
});
